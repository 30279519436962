'use client';

import Script from 'next/script';

export function AttentiveScript() {
  const attentiveShopId =
    process.env.NEXT_PUBLIC_ATTENTIVE_SHOP_ID || 'brandedbills';

  const inlineScript = `
    !function(w){
      w.attn_d0x0b_evt=[];
      var n=w.attentive={analytics:{}},a=["enable","disable","track","pageView","productView","addToCart","purchase"];
      function e(n){return function(){w.attn_d0x0b_evt.push({func:n,args:arguments})}}
      for(var i=0,c=a.length;i<c;i++)n.analytics[a[i]]=e(a[i])
    }(window);
  `;

  return (
    attentiveShopId && (
      <>
        <Script
          id={`attentive-inlinescript-${attentiveShopId}`}
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: inlineScript }}
        />
        <Script
          id={`attentive-script-${attentiveShopId}`}
          strategy="afterInteractive"
          src={`https://cdn.attn.tv/${attentiveShopId}/dtag.js`}
        />
      </>
    )
  );
}
