import Script from 'next/script';

import { useDataLayerAccount } from './useDataLayerAccount';
import { useDataLayerCart } from './useDataLayerCart';
import { useDataLayerCollection } from './useDataLayerCollection';
import { useDataLayerCustomer } from './useDataLayerCustomer';
import { useDataLayerInit } from './useDataLayerInit';
import { useDataLayerProduct } from './useDataLayerProduct';
import { useDataLayerSearch } from './useDataLayerSearch';
import { useDataLayerSubscribe } from './useDataLayerSubscribe';
import { Analytics } from './Analytics';
import { OpensendTracking } from './OpensendTracking';
import { AttentiveScript } from './AttentiveScript';

// Debug via Elevar's Data Layer Listener, add to the console:
// Turn on: window.ElevarGtmSuiteListener.utils.debug(true)
// Turn off: window.ElevarGtmSuiteListener.utils.debug(false)

export function DataLayer({ product }) {
  const { generateUserProperties, userProperties } = useDataLayerInit();

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    userProperties,
  });

  useDataLayerAccount({
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerCart({
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    product,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSubscribe({
    userDataEventTriggered,
  });

  return (
    <>
      <Analytics />
      <OpensendTracking />
      <AttentiveScript />
      <Script
        id="elevar-dl-listener-config"
        type="module"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `const settings = { proxyPath: "/elevar/hits.getelevar.com" };
          const config = (await import("/elevar/hits.getelevar.com/static/configs/db415ab5975e80cf7362f8d5b98b070ce108f5f1/config.js")).default;
          const scriptUrl = settings.proxyPath
            ? settings.proxyPath + config.script_src_custom_pages_proxied
            : config.script_src_custom_pages;

          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config, settings);
          }`,
        }}
      />
    </>
  );
}

DataLayer.displayName = 'DataLayer';
